import {Remarkable} from "remarkable";
import moment from "moment";
import {getOptionValueName, optionNames} from "@/data/options";
import {imageInteractions, setInteractions} from "@/data/interactions";

export const formatMessage = (message) =>  {
    const md = new Remarkable;
    const html = md.render(message);
    const block = document.createElement('div');
    block.innerHTML = html;

    block.querySelectorAll('code').forEach((element) => {
        element.classList.add('fw-bolder');
        if (element.parentElement.innerText === element.innerText) {
            element.parentElement.classList.add('message-block__options');
        }
    });

    block.querySelectorAll('ol').forEach((element) => {
        element.classList.add('mb-0');
    });

    block.querySelectorAll('ul').forEach((element) => {
        element.classList.add('mb-0')
    });

    block.querySelectorAll('p').forEach((element) => {
        element.classList.add('mb-1');
    });

    block.childNodes.forEach((element) => {
        if (element.nodeName !== '#text') {
            //element.classList.add('mb-0');
        }
    })
    return block.innerHTML;
}

const formatSpeakerName = (name) => {
  const speakerNumber = Number(name.replace('SPEAKER_', '')) + 1;
  return `Спикер ${speakerNumber}:`;
}

export const formatTranscription = (data, isDisplayFormatDefault = true) => {
  let html = '';

  if (!data.hasOwnProperty('segments')) {
    return null;
  }

  for (const segment of data.segments) {
    const speaker = segment.hasOwnProperty('speaker') ?
      `<span class="fw-bolder">${formatSpeakerName(segment.speaker)}</span>` : '';

    const time = `<span class="text-primary me-2">
                         ${formatSecondsToMinutes(segment.start)} - ${formatSecondsToMinutes(segment.end)}
                         </span>`;

    if (isDisplayFormatDefault) {
      if (speaker !== '') {
        html += `<p class="mb-2">
            ${speaker}
            <br>
            ${time}
            - ${segment.text}
            </p>`;
      } else {
        html += `<p class="mb-2">
            ${time}
            - ${segment.text}
            </p>`;
      }
    } else {
      html += `<p class="mb-2">
            ${speaker}
            ${time}
            <br>
            - ${segment.text}
            </p>`;
    }

  }

  return html;
}

export const formatTime = (created_at, forChat = true) => {
    if (moment().format('YYYYMD') === moment(created_at).format('YYYYMD') && forChat) {
        return moment(created_at).format('HH:mm:ss')
    }

    let format = 'DD.MM'
    if (moment().format('YYYY') !== moment(created_at).format('YYYY') || !forChat) {
        format += '.YYYY'
    }
    const time = forChat ? ' [в] HH.mm' : ' HH.mm';

    return moment(created_at).format(format + time);
}

export const formatSecondsToMinutes = (seconds) => {
  const duration = moment.duration(seconds, 'seconds');

  if(duration.asHours() < 1) {
    return moment.utc(duration.asMilliseconds()).format('mm:ss');
  } else {
    return moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
  }
}

export const formatStaticPageText = (text) => {
    const md = new Remarkable();
    const html = md.render(text);
    const block = document.createElement('div');
    block.innerHTML = html;
    return block.innerHTML;
}

export const formattedOptions = (options, aiSlug) => {
  if(!options.action_name) {
    const formatted = ['<div class="w-100">Дополнительные настройки:</div>'];

    let optionsCount = 0;
    for(const [name, value] of Object.entries(options)) {
      if (optionNames[name]) {
        formatted.push(`<div class="w-100">${optionNames[name]}: ${getOptionValueName(aiSlug, value)}</div>`);
        optionsCount += 1;
      }
    }

    return optionsCount > 0 ? formatted.join('') : null;
  }
  const interaction = [
    ...imageInteractions,
    ...setInteractions]
    .find(({interaction, value}) => interaction === options.action_name && value === options.action_param);

  return `Взаимодействие: ${interaction.name}`;
}
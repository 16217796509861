import axios from "axios";
import {router} from "@/router";

export class Api {
    constructor() {
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
        axios.defaults.withCredentials = true;
        axios.defaults.baseURL = process.env.VUE_APP_BASE_URL + process.env.VUE_APP_API_PATH;
        axios.interceptors.response.use(response => {
          return response;
        }, error => {
          if (error.response && error.response.status === 429) {
            router.push({name: 'Waiting'});
            return Promise.resolve();
          }
          return Promise.reject(error);
        });

        if (localStorage.getItem('neyro_api_token')) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('neyro_api_token');
            this.token = 'Bearer ' + localStorage.getItem('neyro_api_token');
        }
    }

    async authorize({email, password}) {
        try {
            await axios.get(process.env.VUE_APP_BASE_URL + 'sanctum/csrf-cookie');
            const response = await axios.post('/login', {email, password});

            if (response.status === 200 && await response.data.token) {
                await localStorage.setItem('neyro_api_token', await response.data.token);
                axios.defaults.headers.common['Authorization'] = await 'Bearer ' + localStorage.getItem('neyro_api_token');
                this.token = await 'Bearer ' + localStorage.getItem('neyro_api_token');
                return true;
            }

            return false;
        } catch (e) {
            return false;
        }
    }

    async registrate({email, password}) {
        try {
            await axios.get(process.env.VUE_APP_BASE_URL + 'sanctum/csrf-cookie');
            const response = await axios.post('/auth', {email, password});

            if (response.status === 200 && await response.data.token) {
                await localStorage.setItem('neyro_api_token', await response.data.token);
                axios.defaults.headers.common['Authorization'] = await 'Bearer ' + localStorage.getItem('neyro_api_token');
                this.token = await 'Bearer ' + localStorage.getItem('neyro_api_token');
                return true;
            }

            return false;
        } catch (e) {
            try {
                return { error: e.response.data.message };
            } catch (e) {
                return { error: 'Неизвестная ошибка' };
            }
        }
    }

    async logout() {
        try {
            const response = await axios.post('/logout');
            return response.status === 200;
        } catch (e) {
            return false;
        }
    }

    async getResetPasswordUrl(email) {
        try {
            const response = await axios.post('/forgot-password', {email});
            return response.status === 200;
        } catch (e) {
            try {
                return { error: e.response.data.message };
            } catch (e) {
                return { error: 'Неизвестная ошибка' };
            }
        }
    }

    async resetPassword(data) {
        try {
            const response = await axios.post('/reset-password', data);
            return response.status === 200;
        } catch (e) {
            try {
                return { error: e.response.data.message };
            } catch (e) {
                return { error: 'Неизвестная ошибка' };
            }
        }
    }

    async tryConnection() {
        try {
            const response = await axios.get('/ais');
            return response.status === 200;
        } catch (e) {
            return false;
        }
    }

    async getChatsData(cursor = null) {
        const endPoint = cursor ? `/chats?cursor=${cursor}`: '/chats'
        try {
            const response = await axios.get(endPoint, {
                params: {
                    only_me: 1,
                }
            });
            return await response.data;
        } catch (e) {
            return false;
        }
    }

    async getChat(id) {
      try {
        const response = await axios.get(`chats/${id}`);
        return await response.data;
      } catch (e) {
        return false;
      }
    }

    async updateChat({id, name}) {
      try {
        const response = await axios.patch(`chats/${id}`, {name});
        return response.status === 202;
      } catch (e) {
        return false;
      }
    }

    async addPublicLinkTokenToChat(id) {
      try {
        return  axios.get(`chats/${id}/token`, {});
      } catch (e) {
        return false;
      }
    }

    async getAisData(userHaveChats = null) {
        const endPoint = userHaveChats ? '/ais?user_have_chats_of_ai=1' : '/ais';
        try {
            const response = await axios.get(endPoint);
            return await response.data;
        } catch (e) {
            return false;
        }
    }

    async getChatMessages(id, cursor = null) {
        const endPoint = cursor ? `/chats/${id}/messages?cursor=${cursor}`: `/chats/${id}/messages`;
        try {
            const response = await axios.get(endPoint);
            return await response.data;
        } catch (e) {
            return false;
        }
    }

    async getPublicChatMessages(id, chat_token) {
      try {
        const response = await axios.get(`chats/${id}/public/messages`, {
          params: {
            chat_token,
          }
        });
        return await response.data;
      } catch (e) {
        return false;
      }
    }

    async createChatCopy(id, chat_token) {
      try {
        const response = await axios.post(`chats/${id}/public/copy`, {
            chat_token,
        });
        return await response.data;
      } catch (e) {
        return false;
      }
    }

    async getMessage(chatId, messageId) {
        try {
            const response = await axios.get(`/chats/${chatId}/messages/${messageId}`);
            return await response.data;
        } catch (e) {
            return false;
        }
    }

    async storeChat(chat) {
        try {
            const response = await axios.post('/chats', chat);
            return await response.data;
        } catch (e) {
            return false;
        }
    }

    async uploadFilesToChat({id, data, config}){
      try {
        const response = await axios.post(`/chats/${id}/upload_file`, data, {
          onUploadProgress: config.onUploadProgress,
        });
        return await response.data;
      } catch (e) {
        return false;
      }
    }

    async deleteFileFromChat(id, uuid) {
      try {
        await axios.post(`/chats/${id}/delete_file`, {uuid});
        return true;
      } catch (e) {
        return false;
      }
    }

    async addPropertiesToChat(id, properties) {
      try {
        await axios.post(`/chats/${id}/properties`, properties);
        return true;
      } catch (e) {
        return false;
      }
    }

    async storeMessage(id, message) {
        try {
            const response = await axios.post(`/chats/${id}/messages`, message);
            return await response.data;
        } catch (e) {
            try {
                if (await e.response.status === 402) {
                    return { error: e.response.data.message, balance_error: true };
                }
                return { error: e.response.data.message };
            } catch (e) {
                return { error: 'Неизвестная ошибка' };
            }
        }
    }

    async updateMessage(chatId, messageId, data) {
      try {
        const response = await axios.post(`/chats/${chatId}/messages/${messageId}`, {
          ...data,
          _method: 'PUT',
        });
        return await response.data;
      } catch (e) {
        return false;
      }
    }

    async storeAction({ chatId, messageId, action }) {
        try {
            const response = await axios.post(`/chats/${chatId}/messages/${messageId}/actions?type=${action}`);
            return await response.data;
        } catch (e) {
            return false;
        }
    }

    async deleteAction({ chatId, messageId, actionId }) {
        try {
            const response = await axios.delete(`/chats/${chatId}/messages/${messageId}/actions/${actionId}`);
            return await response.data;
        } catch (e) {
            return false;
        }
    }

    async deleteChat(id) {
        try {
            const response = await axios.delete(`/chats/${id}`);
            return await response.status === 204;
        } catch (e) {
            return false;
        }
    }

  async deleteChats(ids) {
    try {
      const response = await axios.delete(`/chats`, {
        data: {
          ids,
        },
      });
      return await response.status === 204;
    } catch (e) {
      return false;
    }
  }

    async getFavourites({cursor, ai}) {
        try {
            const response = await axios.get('/actions', {
                params: {
                    type: 'favourite',
                    cursor,
                    ai,
                },
            });
            return await response.data;
        } catch (e) {
            return false;
        }
    }

    async saveUserData(data) {
        try {
            const response = await axios.patch('/users', data);
            return await response.data;
        } catch (e) {
            try {
                return { error: e.response.data.message };
            } catch (e) {
                return { error: 'Неизвестная ошибка' };
            }
        }
    }

    async getUserData() {
        try {
            const response = await axios.get('/users');
            return await response.data;
        } catch (e) {
            return false;
        }
    }

    async getTransactions({page, sort, per_page, date_from, date_to, type}) {
        try {
            const response = await axios.get('/transactions', {
                params: {
                    page,
                    per_page,
                    sort: sort ? 1 : 0,
                    date_from,
                    date_to,
                    type,
                }
            });
            return await response.data;
        } catch (e) {
            return false;
        }
    }

    async getPage(slug) {
        try {
            const response = await axios.get('/pages', {
                params: {
                    slug,
                }
            });
            return await response.data;
        } catch (e) {
            return false;
        }
    }

    async pay(data) {
        try {
            const response = await axios.post('/payments', data);
            return await response.data;
        } catch (e) {
          try {
            return { error: e.response.data.message };
          } catch (e) {
            return { error: 'Неизвестная ошибка' };
          }
        }
    }

    async resolvePay(data) {
        try {
            const response = await axios.post('/payments/resolve', data);
            return await response.data;
        } catch (e) {
            return false;
        }
    }

    async cancelAutopay() {
        try {
            const response = await axios.delete('/payments');
            return true;
        } catch (e) {
            return false;
        }
    }

    async getMedias (data) {
      try {
        const response = await axios.get('/media', {
          params: data
        });
        return await response.data;
      } catch (e) {
        return false;
      }
    }
}
import Pusher, {Channel} from "pusher-js";
import Echo from "laravel-echo";

export class Listener {
    constructor(token, store) {
        Pusher.logToConsole = true;
        this.pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
            cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
            encrypted: true,
            auth: {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'text/plain',
                    'Accept': 'application/json',
                }
            },
            authEndpoint: process.env.VUE_APP_BASE_URL + process.env.VUE_APP_API_PATH + 'broadcasting/auth',
        });



        this.store = store;
    }

     subscribe(id) {
        const channelName = `private-chats.${id}`;
        if (!this.pusher.allChannels().find(({name}) => name === channelName)) {
            const channel = this.pusher.subscribe(channelName);
            channel.bind('MessageResolved', (data) => {
                this.store.dispatch('resolveMessage', { id, data });
            });
            channel.bind('ChatRenamed', (data) => {
                this.store.commit('resetChatName', {id, name: data.name});
            });
            channel.bind('SentToThirdPartyService', () => {
              this.store.dispatch('resolveWaitingNotification', {id});
            })
        }
    }

    unsubscribe(id) {
        this.pusher.unsubscribe(`private-chats.${id}`);
    }

    listenPaymentsAlerts(id) {
        const channelName = `private-transactions.${id}`;
        if (!this.pusher.allChannels().find(({name}) => name === channelName)) {
            const channel = this.pusher.subscribe(channelName);
            channel.bind('TransactionResolved', (data) => {
                this.store.dispatch('showPaymentNotification', data);
            });
        }
    }
}
export const getMainBranch = (messages, firstParentId) =>  {
    if (messages.length ===  0) return messages;
    const mainBranch = [];
    let lastMessage = firstParentId ? messages.find(m => m.id === firstParentId) : messages[0];
    mainBranch.push(lastMessage);

    messages.forEach((message) => {
        if (message.id === lastMessage.parent_id) {
            mainBranch.push(message);
            lastMessage = message;
        }
    });

    return mainBranch;
};

export const switchBranch = (messages, messagesData, { messageId, newMessageId }) => {
    const newMessages = messages.filter(message => message.id < messageId);
    let freshBranchMessage = messagesData.find(message => message.id === newMessageId);
    newMessages.push(freshBranchMessage);

    messagesData.sort((m1, m2) => m1.id - m2.id).forEach((nextMessage) => {
        if (Number(nextMessage.parent_id) === Number(freshBranchMessage.id)) {
            newMessages.push(nextMessage);
            freshBranchMessage = nextMessage;
        }
    });
    return newMessages;
};

// переключение веток при получении ответа на ререндеринг старого (не последнего в диалоге) соообщения
export const switchToUpperBranch = (messages, messagesData, { messageId, newMessageId }) => {
  const newMessages = messages.filter(message => message.id <= messageId);
  let freshBranchMessage = messagesData.find(message => message.id === newMessageId);
  newMessages.push(freshBranchMessage);

  messagesData.sort((m1, m2) => m1.id - m2.id).forEach((nextMessage) => {
    if (Number(nextMessage.parent_id) === Number(freshBranchMessage.id)) {
      newMessages.push(nextMessage);
      freshBranchMessage = nextMessage;
    }
  });
  return newMessages;
};

export const getVariants = (messages, mainBranch) => {
    const variants = [];
    Object.entries(Object.groupBy(messages, (m) => m.parent_id)).forEach(([parentId, group]) => {
        const ids = group.map(el => el.id)
        let currentMessage = null;
        ids.forEach((id) => {
            if (!currentMessage) {
                currentMessage = mainBranch.find((message) => message.id === id);
            }
        });
        variants.push({ids: ids.reverse(), parentId, current: currentMessage? currentMessage.id : null});
    });
    return variants;
};



export function isToday(updatedAt) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return updatedAt.getTime() >= today.getTime();
}

export function isMoreThan1LessThan7Days(updatedAt) {
  const oneDayAgo = new Date();
  oneDayAgo.setHours(0, 0, 0, 0);
  const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000); // 7 days ago
  return updatedAt.getTime() < oneDayAgo.getTime() && updatedAt.getTime() >= sevenDaysAgo.getTime();
}

export function isMoreThan7LessThan30Days(updatedAt) {
  const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000); // 7 days ago
  const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000); // 30 days ago
  return updatedAt.getTime() < sevenDaysAgo.getTime() && updatedAt.getTime() >= thirtyDaysAgo.getTime();
}

export function isMoreThan30Days(updatedAt) {
  const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000); // 30 days ago
  return updatedAt.getTime() < thirtyDaysAgo.getTime();
}
